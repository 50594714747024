import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../../components/Modais/styles';
import { observer } from 'mobx-react-lite';
import Botao from '../../../../components/Botoes/Botao';
import { Box, Button, IconButton } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Formatter from '../../../../services/Formatter';
import { apiMain, aqReports } from '../../../../services/utils';
import useStore from '../../../../services/hooks/useStore';
import { newConfig } from '../../../../components/Tabela';
import MaterialTable, { Column } from 'material-table';
import * as dateFns from 'date-fns';
import CloseIcon from '@material-ui/icons/Close';

type Categoria = {
  id: number;
  nome: string;
};

type ContaBancaria = {
  id: number;
  nome: string;
};

type Despesa = {
  categoria: Categoria;
  conta_bancaria: ContaBancaria;
  data_pagamento: string;
  descricao: string;
  movimentacao_id: number;
  numero_parcela: string;
  parcela_id: number;
  quantidade: number;
  valor_parcela: number;
  valor_total: number;
  valor_unitario: number;
};

type ResumoDespesaFornecedor = {
  id: number;
  nome: string;
  valor: string;
};

type Props = ModalProps & {
  despesaFornecedor: ResumoDespesaFornecedor;
  dataInicial: MaterialUiPickersDate;
  dataFinal: MaterialUiPickersDate;
};

interface FunctionParams {
  dataInicial?: string;
  dataFinal?: string;
}

const ModalDespesasFornecedor: React.FC<Props> = ({
  open,
  handleClose,
  despesaFornecedor,
  dataInicial: dataInicialParam,
  dataFinal: dataFinalParam,
}) => {
  const store = useStore();
  const dataInicial = dataInicialParam || new Date();
  const dataFinal = dataFinalParam || new Date(new Date().setMonth(dataInicial.getMonth() - 1));
  const [filtroData, setFiltroData] = useState({
    dataInicial: {
      valor: dataFinal as MaterialUiPickersDate,
      valorFormatado: Formatter.getData(Formatter.dateToString(dataFinal)),
    },
    dataFinal: {
      valor: dataInicial as MaterialUiPickersDate,
      valorFormatado: Formatter.getData(Formatter.dateToString(dataInicial)),
    },
  });
  const [total, setTotal] = useState(0);
  const [dadosTabela, setDadosTabela] = useState<Despesa[]>([]);

  const colunas: Column<Despesa>[] = [
    {
      field: 'data_pagamento',
      title: 'Data Pgto',
      width: 150,
      render: (rowData) => {
        return dateFns.format(new Date(rowData?.data_pagamento), 'dd/MM/yyyy');
      },
    },
    {
      field: 'categoria.nome',
      title: 'Categoria',
      width: 150,
    },
    {
      field: 'descricao',
      title: 'Descrição',
      width: 150,
      render: (rowData) => {
        return rowData.descricao.toUpperCase();
      },
    },
    {
      field: 'conta_bancaria.nome',
      title: 'Conta bancária',
      width: 150,
    },
    {
      field: 'quantidade',
      title: 'Quantidade',
      width: 90,
      align: 'center',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData?.quantidade);
      },
    },
    {
      field: 'valor_unitario',
      title: 'Valor Unit (R$)',
      width: 150,
      align: 'right',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData?.valor_unitario);
      },
    },
    {
      field: 'valor_total',
      title: 'Valor Total (R$)',
      width: 150,
      align: 'right',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData?.valor_total);
      },
    },
    {
      field: 'numero_parcela',
      title: 'Nº Parcela',
      width: 120,
    },
    {
      field: 'valor_parcela',
      title: 'Valor Parcela',
      width: 150,
      align: 'right',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData?.valor_parcela);
      },
    },
  ];

  async function buscarDespesasPorFornecedor({ dataInicial, dataFinal }: FunctionParams) {
    const fornecedorId = despesaFornecedor?.id;
    if (!fornecedorId) return;
    await aqReports
      .get('/relatorio/' + store.propriedade?.uuid + '/fornecedor/' + fornecedorId + '/despesas', {
        params: {
          data_inicio: dataInicial || filtroData.dataInicial.valorFormatado,
          data_fim: dataFinal || filtroData.dataFinal.valorFormatado,
        },
      })
      .then((res) => {
        const results = res?.data?.results as Despesa[];
        const tot = res?.data?.total as number;
        setTotal(tot ?? 0);
        setDadosTabela(results ?? []);
      });
  }

  async function baixarPDF() {
    const fornecedorId = despesaFornecedor?.id;
    if (!fornecedorId) return;
    try {
      const pdf = await apiMain.get(
        '/gerador/pdf/relatorio/' + store.propriedade?.uuid + '/fornecedor/' + fornecedorId + '/despesas/',
        {
          params: {
            data_ini: filtroData.dataInicial.valorFormatado,
            data_fim: filtroData.dataFinal.valorFormatado,
            authori: store.token,
          },
        },
      );
      window.open(pdf.request.responseURL, '_blank');
    } catch {
      store.notificar('Houve algum problema ao gerar o PDF! Favor entrar em contato com o Suporte.');
    }
  }

  async function baixarCSV() {
    const fornecedorId = despesaFornecedor?.id;
    if (!fornecedorId) return;
    try {
      const csv = await apiMain.get(
        '/gerador/csv/relatorio/' + store.propriedade?.uuid + '/fornecedor/' + fornecedorId + '/despesas/',
        {
          params: {
            data_ini: filtroData.dataInicial.valorFormatado,
            data_fim: filtroData.dataFinal.valorFormatado,
            authori: store.token,
          },
        },
      );
      window.open(csv.request.responseURL, '_blank');
    } catch {
      store.notificar('Houve algum problema ao gerar o CSV! Favor entrar em contato com o Suporte.');
    }
  }

  function fecharModal() {
    handleClose();
  }

  useEffect(() => {
    const dataInicialFormatada = Formatter.getData(Formatter.dateToString(dataInicialParam));
    const dataFinalFormatada = Formatter.getData(Formatter.dateToString(dataFinalParam));
    setFiltroData({
      dataInicial: {
        valor: dataInicialParam as MaterialUiPickersDate,
        valorFormatado: dataInicialFormatada,
      },
      dataFinal: {
        valor: dataFinalParam as MaterialUiPickersDate,
        valorFormatado: dataFinalFormatada,
      },
    });
    buscarDespesasPorFornecedor({ dataInicial: dataInicialFormatada, dataFinal: dataFinalFormatada });
  }, [dataInicialParam, dataFinalParam, despesaFornecedor]);

  return (
    <Modal onClose={fecharModal} open={open}>
      <ModalHeader>
        Despesas do {despesaFornecedor.nome}
        <IconButton onClick={fecharModal}>
          <CloseIcon />
        </IconButton>
      </ModalHeader>
      <ModalBody>
        <Box display="flex" alignItems="center" gridGap="2rem" width="100%">
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            value={filtroData.dataInicial.valor}
            onChange={(date) => {
              setFiltroData({
                ...filtroData,
                dataInicial: {
                  valor: date,
                  valorFormatado: date ? Formatter.getData(Formatter.dateToString(date)) : '',
                },
              });
            }}
          />
          <p>até</p>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            value={filtroData.dataFinal.valor}
            onChange={(date) => {
              setFiltroData({
                ...filtroData,
                dataFinal: {
                  valor: date,
                  valorFormatado: date ? Formatter.getData(Formatter.dateToString(date)) : '',
                },
              });
            }}
          />
          <Botao onClick={() => buscarDespesasPorFornecedor({})} variant="contained" cor="#059de7">
            Filtrar
          </Botao>

          <Button className="blue" onClick={baixarPDF} fullWidth>
            <Box display="flex">
              <div style={{ paddingRight: '10px', fontFamily: 'Roboto', color: '#303030', fontSize: '12px' }}>
                Exportar PDF
              </div>
              <img src={'/images/exportPDF.svg'} />
            </Box>
          </Button>
          <Button className="blue" onClick={baixarCSV} fullWidth>
            <Box display="flex">
              <div style={{ paddingRight: '10px', fontFamily: 'Roboto', color: '#303030', fontSize: '12px' }}>
                Exportar CSV
              </div>
              <img src={'/images/exportCSV.svg'} />
            </Box>
          </Button>
        </Box>

        <Box>
          {dadosTabela?.length > 0 ? (
            <>
              <MaterialTable
                columns={colunas}
                data={dadosTabela}
                options={{
                  ...newConfig.options,
                  pageSize: 50,
                  minBodyHeight: '20rem',
                  maxBodyHeight: '20rem',
                }}
                localization={newConfig.localization}
                style={{ ...newConfig.style, width: '59rem' }}
              />
              <p>Total de despesas: {total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
            </>
          ) : (
            <Box height="20rem" display="flex" justifyContent="center" alignItems="center">
              Filtre por um intervalo de datas
            </Box>
          )}
        </Box>
      </ModalBody>
    </Modal>
  );
};

export default observer(ModalDespesasFornecedor);
