/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from 'react';
import Header from '../../../components/Header';
import { Flex } from '../../../components/NavBar/styles';
import AddBtn from '../../../components/Botoes/AddBtn';
import AddIcon from '@material-ui/icons/Add';
import useStore from '../../../services/hooks/useStore';
import Select from '../../../components/Select';
import { useLocalStore, observer } from 'mobx-react-lite';
import Filtro from '../../../components/Filtro';
import ActionButton from '../../../components/Tabela/ActionButton';
import { Body } from '../../../components/Body';
import MaterialTable, { Column } from 'material-table';
import { config } from '../../../components/Tabela';
import useModal from '../../../services/hooks/useModal';
import Confirmar from '../../../components/Modais/Confirmar';
import FormFornecedor from './FormFornecedor';
import { useHistory } from 'react-router-dom';
import syncModel from '../../../services/models/Sync';
import Formatter from '../../../services/Formatter';
import { TextField } from '@material-ui/core';
import { permissoes } from '../../../services/utils/permissoes';

const Fornecedores: React.FC = () => {
  const tableRef = useRef();
  const store = useStore();
  const history = useHistory();
  const modalFornecedor = useModal();
  const modalExclusao = useModal();
  const [fornecedoresPesquisados, setFornecedoresPesquisados] = useState<Fornecedor[] | null>();
  const state = useLocalStore(() => ({
    pageSize: 0,
    setPageSize(value: number) {
      this.pageSize = value + 1;
    },
    filtroRapidoNome: '',

    filtroRapidoTipo: null as SelectRow | null,
    selectedFornecedor: 0,

    sync: false,
  }));
  store.propriedade && store.validarPermissao(permissoes.FINANCEIRO_FORNECEDORES_LEITURA, history);
  const temPermissaoEscrita = store.checkPermissions(permissoes.FINANCEIRO_FORNECEDORES_ESCRITA);

  const columns: Column<object>[] = [
    {
      field: 'nome',
      title: 'Nome',
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'acoes',
      render: ({ id }: any) => (
        <ActionButton
          editar={
            temPermissaoEscrita
              ? () => {
                  state.selectedFornecedor = id;
                  modalFornecedor.open();
                }
              : undefined
          }
          excluir={
            temPermissaoEscrita
              ? () => {
                  if (store.movimentacoes.arr!.some((movimentacao) => movimentacao.fornecedor === id)) {
                    store.notificar('Já existem registros com esse fornecedor');
                  } else {
                    state.selectedFornecedor = id;
                    modalExclusao.open();
                  }
                }
              : undefined
          }
        />
      ),
      ...(true && ({ width: 25 } as object)),
    },
    {
      field: 'tipo',
      title: 'Tipo',
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'email',
      title: 'Email',
      ...(true && ({ width: 200 } as object)),
    },
    {
      field: 'cpf_cnpj',
      title: 'CPF/CNPJ',
      render: (rowData: any) => {
        return rowData.cpf_cnpj ? Formatter.formatCPFCNPJ(rowData.cpf_cnpj) + '71-' : null;
      },
      ...(true && ({ width: 130 } as object)),
    },
    {
      field: 'fone',
      title: 'Telefone',
      ...(true && ({ width: 130 } as object)),
    },
    {
      field: 'cidade',
      title: 'Cidade',
      ...(true && ({ width: 120 } as object)),
    },
    {
      field: 'estado',
      title: 'Estado',
      ...(true && ({ width: 120 } as object)),
    },
  ];
  const rows = (fornecedores: Fornecedor[]): object[] => {
    if (fornecedores.length) {
      if (fornecedores.length >= 50) {
        state.setPageSize(50);
      } else {
        state.setPageSize(fornecedores.length);
      }
    } else {
      state.setPageSize(0);
    }

    return fornecedores.map((fornecedor) => {
      return {
        id: fornecedor.id,
        nome: fornecedor.nome,
        tipo: fornecedor.fornecedor_tipo.nome,
        email: fornecedor.email,
        cpf_cnpj: fornecedor.cpf_cnpj,
        fone: fornecedor.fone,
        cidade: fornecedor.cidade.nome,
        estado: fornecedor.estado.nome,
      };
    });
  };

  async function sync(): Promise<void> {
    await syncModel.fornecedores();
    state.sync = true;
  }

  function limparFiltro(): void {
    state.filtroRapidoTipo = null;
    setFornecedoresPesquisados(undefined);
  }

  async function deletar(): Promise<void> {
    modalExclusao.close();
    store.toggleLoader();
    await store.fornecedores.excluir(state.selectedFornecedor);
    store.toggleLoader();
  }
  function fastSearch(e: React.ChangeEvent<HTMLInputElement>): void {
    const value = e.target.value;
    state.filtroRapidoNome = value;
    const clientes = store.fornecedores?.filter((cliente) => {
      if (cliente.nome) {
        const nomeCliente = cliente.nome.toLowerCase();
        return nomeCliente.includes(value.toLowerCase());
      } else return false;
    });
    setFornecedoresPesquisados(clientes);
  }

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Fornecedores'), []);

  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, [store.propriedade]);

  useEffect(() => {
    if (state.filtroRapidoTipo) {
      const fornecedores = store.fornecedores.getByTipo(parseInt(state.filtroRapidoTipo?.value));
      setFornecedoresPesquisados(fornecedores);
    }
    // eslint-disable-next-line
  }, [state.filtroRapidoTipo]);

  const ExcluirFornecedor: React.FC = () => {
    const fornecedor = store.fornecedores.get(state.selectedFornecedor);

    return (
      <div style={{ width: '100%' }}>
        <b>Nome: </b> {fornecedor && fornecedor.nome}
        <br />
        <b>Tipo: </b> {fornecedor && fornecedor.fornecedor_tipo.nome}
        <br />
      </div>
    );
  };

  return state.sync ? (
    <>
      <Header>
        <Flex>
          <AddBtn
            disabled={!temPermissaoEscrita}
            onClick={() => {
              state.selectedFornecedor = 0;
              modalFornecedor.open();
            }}
            text="ADICIONAR"
            Icon={AddIcon}
          />
          <div style={{ width: 200, marginRight: 20 }}>
            <Select
              value={state.filtroRapidoTipo}
              placeholder="Tipo"
              onChange={(e: any) => (state.filtroRapidoTipo = e)}
              options={store.tiposDeFornecedor.getSelectRows()}
            />
          </div>
          <TextField onChange={fastSearch} placeholder="Nome/Razão Social" />
        </Flex>
        <Flex>
          <Filtro sync={sync} clear={limparFiltro}></Filtro>
        </Flex>
      </Header>

      <Body grid={true}>
        <div style={{ overflowX: 'hidden', borderRadius: '10px' }}>
          {rows && (
            <MaterialTable
              tableRef={tableRef}
              columns={columns}
              data={rows(fornecedoresPesquisados || store.fornecedores.arr!)}
              options={{ ...config.options, pageSize: state.pageSize }}
              localization={config.localization}
              style={config.style}
            />
          )}
        </div>
      </Body>

      {modalFornecedor.state ? (
        <FormFornecedor
          open={modalFornecedor.state}
          handleClose={modalFornecedor.close}
          fornecedorID={state.selectedFornecedor}
        />
      ) : null}
      <Confirmar
        open={modalExclusao.state}
        content={<ExcluirFornecedor />}
        title="Tem certeza que deseja excluir?"
        handleClose={modalExclusao.close}
        onCancel={modalExclusao.close}
        onConfirm={deletar}
      />
    </>
  ) : null;
};

export default observer(Fornecedores);
